import React from "react"
import Layout from "../components/layout"

export default function NotFound() {
  return (
    <Layout>
      <h1 className="f1">404</h1>
      <p className="f5">
        The page you are looking for is not here.
      </p>

      <p className="f5">
        <a href="/" className="link black dim underline">
          Go to the main page
        </a> instead.
      </p>
    </Layout>
  )
}